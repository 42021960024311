@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.ttf');
  font-weight: 900;
  font-style: 'normal';
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Condensed.ttf');
  font-weight: 600;
  font-style: 'normal';
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf');
  font-weight: 500;
  font-style: 'normal';
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf');
  font-weight: 400;
  font-style: 'normal';
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.ttf');
  font-weight: 200;
  font-style: 'normal';
}
